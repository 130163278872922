<template>
  <div>
    <div style="background-color: #00376F; padding: 24px 0;">
      <div class="home-section">
        <div class="home-section-title">Getting Started</div>
        <div class="home-section-row">
          <div>
            <div
              @click="openInNewTab('https://calendly.com/sales-and-support-team/schedule-a-call-or-a-demo?month=2021-08')">
              <img :src="require('../assets/tiles/b-bhrt-live-demo.png')"/>
              <div class="home-section-item-title">Schedule a LIVE Demo!</div>
            </div>
          </div>
          <div>
            <div @click="goToPage('roles')">
              <img :src="require('../assets/tiles/b-bhrt-provider-roles.png')"/>
              <div class="home-section-item-title">Dosaggio™ Provider Roles</div>
            </div>
          </div>
          <div>
            <div @click="goToPage('enrollment')">
              <img :src="require('../assets/tiles/b-bhrt-provider-application.png')"/>
              <div class="home-section-item-title">New Provider Enrollment</div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-section">
        <div class="home-section-title">Enrolled Providers</div>
        <div class="home-section-row">
          <div>
            <div @click="goToPage('login')">
              <img :src="require('../assets/tiles/b-bhrt-provider-login.png')"/>
              <div class="home-section-item-title">Member Login</div>
            </div>
          </div>
          <div>
            <div @click="goToPage('knowledge-base')">
              <img :src="require('../assets/tiles/b-bhrt-knowledge-base.png')"/>
              <div class="home-section-item-title">Knowledge Base</div>
            </div>
          </div>
          <div>
            <div @click="openInNewTab('https://pelletdose.co/sign-in')">
              <img :src="require('../assets/tiles/b-bhrt-dose-calculator.png')"/>
              <div class="home-section-item-title">Dosing Calculator</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="background-color: #EFFAFE; padding: 24px 0;">
      <div class="home-cta">
        <div>
          <h2>What Is Dosaggio&#8482;?</h2>

          <p>Dosaggio&#8482; is the original, and only patented, pellet therapy dosing platform for medical providers
            who
            wish to implement Dr. Gino Tutera’s proprietary SottoPelle® dosage method.</p>

          <p>Gino Tutera, MD, FACOG was internationally recognized as a pioneer and the foremost leader in the
            field of bio-identical hormone replacement therapy. After years of performing his own research, testing,
            and analysis, Dr. Tutera broke new ground with the SottoPelle® Method, a revolutionary method of
            natural hormone replacement based on his individualized &amp; proprietary bio-identical pellet hormone
            delivery system.</p>

          <p>The Dosaggio&#8482; system of pellet dosing has been voted “Easiest HRT Dosing Platform.” It’s convenient
            online dosing calculator means instant access to dosage calculation with fewer clicks, and without the
            need for clumsy appointments.</p>

          <p>If you are a new provider interested in a proven, patented, HRT dosing method that is faster, easier and
            doses conservatively, take a moment to request access to our short introductory, no obligation, training
            video by <span class="link" @click="$router.push({name: 'enrollment'})">CLICKING HERE</span>.</p>
        </div>
        <div class="dossagio-list">
          <div>
            <h2 style="padding-left: 20px;">Benefits of Dosaggio&#8482;<br/>Pellet Dosing</h2>
            <ul>
              <li>No Contracts</li>
              <li>Affordable Flat Monthly Pricing</li>
              <li>Voted Easiest Pellet Dosing Platform</li>
              <li>The Original &amp; Patented Pellet Dosing Site</li>
              <li>No Appointment Needed to Dose</li>
              <li>Fewer Clicks to Get to a Dosage</li>
              <li>Easy Online Pellet Dosing Calculator</li>
              <li>Extensive Online Knowledge Base</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div style="background-color: #546676; padding: 24px 0;">
      <div class="home-section">
        <div class="home-section-title">Resources</div>
        <div class="home-section-row">
          <div>
            <div @click="goToArticles('payments')">
              <img :src="require('../assets/tiles/b-bhrt-member-payments.png')"/>
              <div class="home-section-item-title">Payments</div>
            </div>
          </div>
          <div>
            <div @click="goToArticle('help')">
              <img :src="require('../assets/tiles/b-bhrt-tech-support.png')"/>
              <div class="home-section-item-title">Help</div>
            </div>
          </div>
          <div>
            <div @click="goToPage('contact')">
              <img :src="require('../assets/tiles/b-bhrt-provider-contact.png')"/>
              <div class="home-section-item-title">Contact Us</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="background-color: #EFFAFE; padding: 24px 0;">
      <div style="padding: 24px; max-width: 1200px; margin: auto;">
        <h2>The Original Pellet Dosing Platform</h2>

        <p>Only Dosaggio™ Follows Dr. Tutera’s Individual Dosing Methods that is widely used today worldwide.</p>

        <p>Having been at the forefront of the hormone replacement therapy market for the past two decades -
          and knowing that Dr. Tutera developed the original HRT dosing site - we have seen many changes in our
          industry. Competitors who copied our original site made changes in the process that often resulted in an
          overdose. Others may not offer dosing for both estrogen and testosterone pellets. Even worse, some
          who use this method recently developed their own pellets but supply no documentation and offer no
          literature behind them, while making claims that they “work” with products that are not bioidentical.</p>

        <p>Dosaggio™ is the first and only patented pellet dosing platform that follows Dr. Tutera’s proprietary
          individual dosage methods. We are the originals who now hold 5 patents!</p>

        <p>Our niche expertise has given us valuable insights into the complex field of pellet dosing and has allowed
          us to adapt to changes in the pellet practice. As a result, Dosaggio™ is seeing a huge upswing in the
          number of providers searching for a competent, experienced company with the golden track record of
          years of experience in pellet therapy.</p>

        <p><span class="link" @click="$router.push({name: 'enrollment'})">CLICK HERE</span> to enroll for a short
          introductory training video, to see how the many convenient pellet
          dosing features in Dosaggio™ can complement your growing hormone replacement therapy practice
          today!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  title: 'Dosaggio™ Educational Center - Home',
  methods: {
    openInNewTab (url) {
      window.open(url, '_blank').focus()
    },

    goToPage (route) {
      this.$router.push({ name: route })
    },

    goToArticle (slug) {
      this.$router.push({
        name: 'article',
        params: { id: slug }
      })
    },

    goToArticles (slug) {
      this.$router.push({
        name: 'articles',
        params: { id: slug }
      })
    }
  }
}
</script>

<style lang="scss">
.home-cta {
  padding: 24px;
  max-width: 1200px;
  margin: auto;
  display: flex;
}

.dossagio-list {
  display: flex;
  justify-content: center;

  > div {
    width: 420px;
    padding-left: 24px;
  }

  li {
    padding-bottom: 12px;
    font-weight: bold;
  }
}

.home-section {
  max-width: 1200px;
  margin: auto auto 24px;
  color: #ffffff;

  &:last-child {
    margin-bottom: 0;
  }

  .home-section-title {
    text-transform: uppercase;
    text-align: center;

    font-size: 36px;
    padding: 24px 12px;
  }

  .home-section-row {
    display: flex;

    .home-section-item-title {
      color: #ffffff;
      padding: 12px;
      text-align: center;
      font-size: 22px;
    }

    > div {
      width: 33.33%;

      > div {
        padding: 24px;
        cursor: pointer;
      }

      img {
        max-width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .home-cta {
    flex-direction: column;
  }

  .dossagio-list {
    justify-content: flex-start;
    margin-left: -22px;
  }

  .dossagio-list > div {
    padding-left: 0;
  }
}

@media only screen and (max-width: 600px) {
  .home-section .home-section-row {
    flex-direction: column;
  }

  .home-section .home-section-row > div {
    width: 100%;
  }
}

</style>
